import { unoTheme } from '@unoTheme'

export function getTheme() {
  const config = useRuntimeConfig()

  const staticPrimaryColor = config.public.theme.staticPrimaryColor
  const staticPrimaryDarkenColor = config.public.theme.staticPrimaryDarkenColor

  return {
    light: {
      dark: false,
      colors: {
        'primary': staticPrimaryColor,
        'on-primary': '#fff',
        'primary-darken-1': staticPrimaryDarkenColor,
        'secondary': unoTheme.colors.secondary,
        'on-secondary': '#fff',
        'secondary-darken-1': unoTheme.colors['secondary-darken-1'],
        'success': unoTheme.colors.success,
        'on-success': '#fff',
        'success-darken-1': unoTheme.colors['success-darken-1'],
        'info': unoTheme.colors.info,
        'on-info': '#fff',
        'info-darken-1': unoTheme.colors['info-darken-1'],
        'warning': unoTheme.colors.warning,
        'on-warning': '#fff',
        'warning-darken-1': unoTheme.colors['warning-darken-1'],
        'error': unoTheme.colors.error,
        'on-error': '#fff',
        'error-darken-1': unoTheme.colors['error-darken-1'],
        'background': '#F8F7FA',
        'on-background': '#2F2B3D',
        'surface': '#fff',
        'on-surface': '#2F2B3D',
        'grey-50': unoTheme.colors.grey[50],
        'grey-100': unoTheme.colors.grey[100],
        'grey-200': unoTheme.colors.grey[200],
        'grey-300': unoTheme.colors.grey[300],
        'grey-400': unoTheme.colors.grey[400],
        'grey-500': unoTheme.colors.grey[500],
        'grey-600': unoTheme.colors.grey[600],
        'grey-700': unoTheme.colors.grey[700],
        'grey-800': unoTheme.colors.grey[800],
        'grey-900': unoTheme.colors.grey[900],
        'grey-light': unoTheme.colors.grey[50],
        'perfect-scrollbar-thumb': '#DBDADE',
        'skin-bordered-background': '#fff',
        'skin-bordered-surface': '#fff',
        'expansion-panel-text-custom-bg': unoTheme.colors.grey[50],
        'body-color': '#6e6b7b',
        'light-gray': unoTheme.colors.grey.light,
        'background-hover': '#f8f8f8',
        'light-black': '#2c2c2c',
        'base-color-black': '#626262',
        'text-color-light-black': '#4b4b4b',
        'wizard-error': unoTheme.colors.error,
      },

      variables: {
        'code-color': '#d400ff',
        'overlay-scrim-background': '#2F2B3D',
        'tooltip-background': '#2F2B3D',
        'overlay-scrim-opacity': 0.5,
        'hover-opacity': 0.06,
        'focus-opacity': 0.1,
        'selected-opacity': 0.08,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'disabled-opacity': 0.4,
        'border-color': '#2F2B3D',
        'border-opacity': 0.12,
        'table-header-color': '#EAEAEC',
        'high-emphasis-opacity': 0.9,
        'medium-emphasis-opacity': 0.7,
        'switch-opacity': 0.2,
        'switch-disabled-track-opacity': 0.3,
        'switch-disabled-thumb-opacity': 0.4,
        'switch-checked-disabled-opacity': 0.3,
        'track-bg': '#F1F0F2',
        'muted': '#B9B9C3',

        // Shadows
        'shadow-key-umbra-color': '#2F2B3D',
        'shadow-xs-opacity': 0.10,
        'shadow-sm-opacity': 0.12,
        'shadow-md-opacity': 0.14,
        'shadow-lg-opacity': 0.16,
        'shadow-xl-opacity': 0.18,
      },
    },
    dark: {
      dark: true,
      colors: {
        'primary': staticPrimaryColor,
        'on-primary': '#fff',
        'primary-darken-1': staticPrimaryDarkenColor,
        'secondary': unoTheme.colors.secondary,
        'on-secondary': '#fff',
        'secondary-darken-1': unoTheme.colors['secondary-darken-1'],
        'success': unoTheme.colors.success,
        'on-success': '#fff',
        'success-darken-1': unoTheme.colors['success-darken-1'],
        'info': unoTheme.colors.info,
        'on-info': '#fff',
        'info-darken-1': unoTheme.colors['info-darken-1'],
        'warning': unoTheme.colors.warning,
        'on-warning': '#fff',
        'warning-darken-1': unoTheme.colors['warning-darken-1'],
        'error': unoTheme.colors.error,
        'on-error': '#fff',
        'error-darken-1': unoTheme.colors['error-darken-1'],
        'background': '#25293C',
        'on-background': '#E1DEF5',
        'surface': '#2F3349',
        'on-surface': '#E1DEF5',
        'grey-50': unoTheme.colors.grey[900],
        'grey-100': unoTheme.colors.grey[800],
        'grey-200': unoTheme.colors.grey[700],
        'grey-300': unoTheme.colors.grey[600],
        'grey-400': unoTheme.colors.grey[500],
        'grey-500': unoTheme.colors.grey[400],
        'grey-600': unoTheme.colors.grey[300],
        'grey-700': unoTheme.colors.grey[200],
        'grey-800': unoTheme.colors.grey[100],
        'grey-900': unoTheme.colors.grey[50],
        'grey-light': '#353A52',
        'perfect-scrollbar-thumb': '#4A5072',
        'skin-bordered-background': '#2F3349',
        'skin-bordered-surface': '#2F3349',
      },
      variables: {
        'code-color': '#d400ff',
        'overlay-scrim-background': '#171925',
        'tooltip-background': '#F7F4FF',
        'overlay-scrim-opacity': 0.6,
        'hover-opacity': 0.06,
        'focus-opacity': 0.1,
        'selected-opacity': 0.08,
        'activated-opacity': 0.16,
        'pressed-opacity': 0.14,
        'dragged-opacity': 0.1,
        'disabled-opacity': 0.4,
        'border-color': '#E1DEF5',
        'border-opacity': 0.12,
        'table-header-color': '#535876',
        'high-emphasis-opacity': 0.9,
        'medium-emphasis-opacity': 0.7,
        'switch-opacity': 0.4,
        'switch-disabled-track-opacity': 0.4,
        'switch-disabled-thumb-opacity': 0.8,
        'switch-checked-disabled-opacity': 0.3,
        'track-bg': '#3A3F57',

        // Shadows
        'shadow-key-umbra-color': '#131120',
        'shadow-xs-opacity': 0.16,
        'shadow-sm-opacity': 0.18,
        'shadow-md-opacity': 0.2,
        'shadow-lg-opacity': 0.22,
        'shadow-xl-opacity': 0.24,
      },
    },
  }
}
